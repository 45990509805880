import { supabase } from '../../supabaseClient';
import { setUserDetails } from '../user/setUserDetails';
import { setIsLoggedIn } from '../user/setIsLoggedIn';
import { processUserAttributes } from '../aws/user/processUserAttributes';
import { supabaseGetEnvValue } from './supabaseGetEnvValue';
import reportError from '../error-handler/reportError';
import { retrySupabaseCall } from './retrySupabaseCall';
import { updateBouceLogoText, STAGES } from '../bouncingLogo.service';
import Sentry from '../error-handler/sentry/sentry.import';

async function supabaseGetUserProfile() {
  try {
    const requestPayload = supabaseGetEnvValue();
    updateBouceLogoText(STAGES.supabaseGetUserProfile);
    Sentry.addBreadcrumb({ message: "supabaseGetUserProfile start" });
    // const result = await retrySupabaseCall(
    //   () => supabase.rpc("bn_get_user", { request_payload: requestPayload }),
    //   'supabaseGetUserProfile'
    // );
    const result = await supabase.rpc('bn_get_user', { request_payload: requestPayload });
    Sentry.addBreadcrumb({ message: "supabaseGetUserProfile done" });
  
    if (!result.error && result.data.user_id) {
      updateBouceLogoText(STAGES.processUserAttributes);
      Sentry.addBreadcrumb({ message: "processUserAttributes start" });
      const userData = await processUserAttributes(result.data);
      Sentry.addBreadcrumb({ message: "processUserAttributes done" });
      setUserDetails(userData);
      setIsLoggedIn(true);
      return userData;
    }
    console.log('Error getting profile data:', result.error);
    if (result.error) {
      reportError({
        message: result.error.message,
        code: 'Error getting profile data',
        result,
        requestPayload,
      });
    }
    return null;
  } catch (error) {
    console.error('Error getting profile data:', error.message);
    reportError({
      message: error.message,
      code: 'Error getting profile data',
      error,
    });
    return null;
  }
}

export { supabaseGetUserProfile };
