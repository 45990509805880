import getAllExtraData from './utils/getAllExtraData';
import reportToSentry from './sentry/reportToSentry';

/**
 *
 * @param {ERROR} errorData
 * @param {Object} extraTag
 */
const reportError = (errorData, extraTag) => {
  const extraData = getAllExtraData(errorData);
  if (extraData.ignore) {
    console.log('Ignoring iap-dev error');
    return;
  }
  reportToSentry(errorData, extraData, extraTag);
};

export default reportError;
