<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <div class="ion-page">
    <ion-header>
      <ion-toolbar color="dark">
        <IonTitle>Select an Airport</IonTitle>
        <ion-buttons
          v-if="showBackButton"
          slot="start"
        >
          <ion-button
            fill="solid"
            color="secondary"
            @click="closeModal(false)"
          >
            <ion-icon :icon="arrowBack" />
          </ion-button>
        </ion-buttons>
        <ion-buttons
          v-else
          slot="start"
        >
          <ion-button
            fill="solid"
            color="secondary"
            @click="closeModal(false)"
          >
            Close
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-searchbar
        v-model.trim="inputValue"
        class="uppercase"
        autocapitalize="on"
        placeholder="Airport name/ICAO code"
        debounce="300"
        autofocus
        @ionInput="changingAirport"
      />
      <div
        v-if="filteredResults.length"
        class="filteredResultsAirportSelector"
      >
        <ion-list>
          <ion-item
            v-for="(airport, $index) in filteredResults"
            :key="$index"
            @click="selectedItem(airport)"
          >
            <ion-icon
              v-if="airport.logDate"
              slot="start"
              :icon="time"
            />
            <ion-icon
              v-else
              slot="start"
              :icon="location"
            />
            <ion-label v-html="highlight(airport)" />
          </ion-item>
        </ion-list>
      </div>
    </ion-content>
  </div>
</template>
<script>
import {
  IonSearchbar,
  IonContent,
  IonHeader,
  IonLabel,
  IonToolbar,
  IonIcon,
  IonList,
  IonItem,
  IonButtons,
  IonTitle,
  IonButton,
  modalController,
} from '@ionic/vue';
import { arrowBack, location, time } from 'ionicons/icons';
import utilityService from '../services/utility.service';
import applicationDataSource from '../datasource/applicationdata.datasource';
import searchHistoryService from '../services/searchHistory.service';

export default {
  name: 'AirportSelector',
  components: {
    IonSearchbar,
    IonContent,
    IonHeader,
    IonLabel,
    IonToolbar,
    IonIcon,
    IonTitle,
    IonList,
    IonItem,
    IonButtons,
    IonButton,
  },
  props: {
    selectorInput: {
      type: String,
      default: '',
    },
    isSubModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.selectorInput,
      arrowBack,
      location,
      time,
      showRecentSearch: false,
      selected: '',
      filteredOptions: [],
      filteredResults: [],
      suggestions: applicationDataSource.airportsListing,
    };
  },
  computed: {
    showBackButton() {
      return this.isSubModal;
    },
  },
  mounted() {
    searchHistoryService.loadAirportHistorySearch();
    this.filteredResults = searchHistoryService.airportHistorySearches.slice(0, 10);
    window.setTimeout(() => {
      const searchbarEle = window.document.querySelector('ion-searchbar');
      if (searchbarEle) {
        searchbarEle.focus();
      }
    }, 1000);
  },
  methods: {
    changingAirport() {
      const vm = this;
      // vm.inputValue = vm.inputValue
      //     ? vm.inputValue.toUpperCase()
      //     : "";
      if (vm.inputValue.length < 2) {
        return;
      }
      const filteredData = vm.suggestions.filter((option) => {
        if (!option.icao_id) {
          return false;
        }
        return (
          option.icao_id.toLowerCase().indexOf(vm.inputValue.toLowerCase())
            > -1
          || option.faa_facility_name
            .toLowerCase()
            .indexOf(vm.inputValue.toLowerCase()) > -1
        );
      });
      vm.filteredResults = filteredData;
    },
    highlight(airport) {
      const vm = this;
      const text = `${airport.icao_id} ${airport.faa_facility_name}`;
      if (!vm.inputValue) {
        return text;
      }
      vm.inputValue = utilityService.sanitizeSearchString(vm.inputValue);
      vm.inputValue = vm.inputValue.replace(/\[/g, '');
      return text.replace(
        new RegExp(vm.inputValue, 'gi'),
        '<span class="boldedText">$&</span>',
      );
    },
    selectedItem(item) {
      const vm = this;
      vm.closeModal(true, item);
    },
    closeModal(isSave, item) {
      let returnData = false;
      if (isSave) {
        searchHistoryService.setAirportHistory(item.icao_id);
        returnData = item;
      }
      modalController.dismiss(returnData);
    },
  },
};
</script>
<style scoped>
.filteredResultsAirportSelector {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin: 10px;
}
.filteredResultsAirportSelector ion-icon {
  margin: 2px 8px 2px 0;
}
</style>
