import { ignoreErrorsList } from './ignoredErrors';
import checkIfReportAPIFailure from './checkIfReportAPIFailure';

const checkIfWeShouldReport = (ex) => {
  // debugger
  try {
    let shouldReport = true;
    ignoreErrorsList.forEach((item) => {
      // need to test for ex.exception!
      if (ex?.exception && JSON.stringify(ex.exception).indexOf(item) > -1) {
        shouldReport = false;
      }
    });
    if (shouldReport && ex.extra) {
      shouldReport = checkIfReportAPIFailure(ex.extra['ErrorProperty:config'], ex.extra.timeoutlag);
    }
    return shouldReport;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export default checkIfWeShouldReport;
