import { modalController } from '@ionic/vue';
import Emitter from '../../bus';
import { userAuthSuccess } from './userAuthSuccess';
import { userAuthFailed } from './userAuthFailed';
import { supabaseGetCurrentUserSession } from '../supabase/supabaseGetCurrentUserSession';
import { updateBouceLogoText, STAGES } from '../bouncingLogo.service';
import Sentry from '../error-handler/sentry/sentry.import';

const asyncAuthFlow = async (options = {}) => {
  updateBouceLogoText(STAGES.supabaseGetCurrentUserSession);
  Sentry.addBreadcrumb({ message: "supabaseGetCurrentUserSession start" });
  const currentSession = await supabaseGetCurrentUserSession();
  Sentry.addBreadcrumb({ message: "supabaseGetCurrentUserSession done" });

  if (currentSession) {
    // Store email in localstorage to help debug in Sentry when needed
    if (currentSession.user?.email) {
      localStorage.setItem('bn_user', currentSession.user.email);
    }

    if (options?.fromMobileAppMagicLinkLogin) {
      // this is for OTP modal to be dismissed
      modalController.dismiss();
    }

    updateBouceLogoText(STAGES.userAuthSuccess);
    Sentry.addBreadcrumb({ message: "userAuthSuccess start" });
    await userAuthSuccess(options?.fromResumeCheck);
    Sentry.addBreadcrumb({ message: "userAuthSuccess done" });

    // emit to hide stripe overlay if exist
    Emitter.emit('hide-stripe-overlay');
  } else {
    await userAuthFailed();
  }
};

export { asyncAuthFlow };
