import { routerReplaceInterface } from '../router.interface';
import { setUserDetails } from './setUserDetails';
import { setIsLoggedIn } from './setIsLoggedIn';
import { supbaseSignOut } from '../supabase/supbaseSignOut';
import reportError from '../error-handler/reportError';

const doSignoutTask = async function () {
  const { error } = await supbaseSignOut();
  if (error) {
    reportError({
      message: error.message,
      code: 'Error doing user signout task',
      error,
    });
    // localStorage.removeItem('supabase_auth');
    setIsLoggedIn(false);
    setUserDetails({});
    routerReplaceInterface({
      name: 'app.login',
    });
  } else {
    setIsLoggedIn(false);
    setUserDetails({});
    routerReplaceInterface({
      name: 'app.login',
    });
  }
};

export { doSignoutTask };
