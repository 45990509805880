import reportError from '../../../../services/error-handler/reportError';

const drawRadarImgeOnCanvas = (ctx, width, height, x, y, imageObject) => {
  try {
    ctx.drawImage(
      imageObject,
      x,
      y,
      width,
      height,
    );
  } catch (ex) {
    console.log('Radar image is broken');
    reportError(ex);
  }
};

export { drawRadarImgeOnCanvas };
