import { supabase } from '../../supabaseClient';
import reportError from '../error-handler/reportError';
// import { retrySupabaseCall } from "./retrySupabaseCall";
import Sentry from '../error-handler/sentry/sentry.import';

async function supabaseGetCurrentUserSession() {
  try {
    // Get the current user session
    Sentry.addBreadcrumb({ message: "supabase.auth.getSession start" });
    const {
      data: { session },
      error,
    } = await supabase.auth.getSession();
    Sentry.addBreadcrumb({ message: "supabase.auth.getSession done" });
    if (error) {
      console.error('Error getting current user session:', error.message);
      reportError({
        message: 'Error in supabase.auth.getSession',
        code: 'supabase.auth.getSession',
        name: error.message,
        error,
        session,
      });
      return null;
    }

    if (session && session.user) {
      return session;
    }
    // console.log('No active session found.');
    return null;
  } catch (error) {
    console.error('Error getting current user session:', error.message);
    reportError(error);
    return null;
  }
}

export { supabaseGetCurrentUserSession };
