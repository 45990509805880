import reportError from './error-handler/reportError';

const STAGES = {
  appOpened: "App Opened",
  checkingApplicationType: "Checking Application Type",
  initializeSentry: "Initializing (Stage 1)",
  checkInternetAvaialble: "Checking Connectivity (Stage 1)",
  setAppInstallDate: "Initializing (Stage 2)",
  doMagiclinkLogin: "Initializing (Stage 3)",
  getDeviceInfo: "Initializing (Stage 4)",
  bindApplicationState: "Binding Application State",
  startNetworkWatcher: "Initializing (Stage 5)  ",
  setNetworkStatus: "Setting Network Status",
  getIsConnectedToInternet: "Checking Connectivity (Stage 2)",
  startApplicationBooting: "Application Booting",
  configStatusBar: "Configuring Status Bar",
  loadRequiredThings: "Loading Required Assets (Stage 1)",
  oneTimePreBootstrapUpdate: "Performing OTU (Pre-Bootstrap)",
  doOneTimeUpdate: "Performing OTU",
  loadDeviceSpecificConfig: "Loading Device-Specific Config",
  loadRequiredDataSource: 'Loading Required Assets (Stage 2)',
  loadApplicationConfig: "Loading Application Config",
  initializePaypal: "Initializing (Stage 6)",
  asyncAuthFlow: "Attempting authentication check",
  mountVueApp: "Mounting App",
  supabaseGetUserProfile: "Downloading User Profile",
  processUserAttributes: "Processing User Attributes",
  supabaseGetCurrentUserSession: "Getting User Session",
  userAuthSuccess: "User Authenticated",
};

document.querySelector('#root-progress-bar').max = Object.keys(STAGES).length + 1;

const updateBouceLogoText = (stage) => {
  try {
    const stageValues = Object.values(STAGES);
    const loadTextElement = document.querySelector(".pre-load-container .load-text");
    loadTextElement.innerHTML = stage;
    const progressBar = document.getElementById("root-progress-bar");
    progressBar.value = stageValues.indexOf(stage) + 1;
  } catch (ex) {
    console.error(ex);
    reportError(ex);
  }
};

setTimeout(() => {
  try {
    const reloadDiv = document.querySelector(".pre-load-container .reload-div");
    if (reloadDiv) {
      reloadDiv.style.display = "inline";
    }
  } catch (ex) {
    console.error(ex);
    reportError(ex);
  }
}, 10000);

document.getElementById('reload-root-btn').addEventListener('click', () => {
  reportError({
    message: "User requested reload",
    code: "User requested reload",
  });
  if (window.Capacitor.platform !== 'web') {
    console.log('Attempt window.location.replace')
    //window.location.href = 'index.html';
    window.location.replace('./index.html')
  } else {
    console.log('Attempt window.location.reload()')
    window.location.reload();
  }
});

export { updateBouceLogoText, STAGES };
