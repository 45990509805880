import { loadingController, toastController } from '@ionic/vue';
import { SUBSCRIPTION_ACTIONS_ENUM, SUBSCRIPTION_MUTATIONS_ENUM } from './subscription.enum';
import { getPaypalServiceHost } from '../../../services/paypal/getPaypalServiceHost';
import httpService from '../../../services/http.service';
import PayPalConst from '../../../constants/paypal.constant';
import AppConst from '../../../constants/app.constant';
import { getPayPalProductsForState } from './utils/getPayPalProductsForState';
import { logResData } from './utils/logResData';
import { getPayPalPlansFromLs } from './utils/getPayPalPlansFromLs';
import { getCacheBuster } from '../../../services/utils/getCacheBuster';
import { supabaseGetAllStripeProducts } from '../../../services/supabase/supabaseGetAllStripeProducts';

const t4QueryParam = '&t4=true';

const getPaypalServiceUrl = (
  endpoint,
  isForT4,
) => getPaypalServiceHost() + endpoint + getCacheBuster() + (isForT4 ? t4QueryParam : '');

export const subscriptionActions = {
  async [SUBSCRIPTION_ACTIONS_ENUM.GET_ALL_STRIPE_PLANS]({ commit }) {
    const resProducts = await supabaseGetAllStripeProducts();
    commit(SUBSCRIPTION_MUTATIONS_ENUM.SET_ALL_STRIPE_PLANS, resProducts);
  },
  async [SUBSCRIPTION_ACTIONS_ENUM.GET_ALL_PAYPAL_PLANS]({ commit, state }) {
    const paypalPlansFromLs = getPayPalPlansFromLs();
    if (paypalPlansFromLs) {
      commit(SUBSCRIPTION_MUTATIONS_ENUM.SET_ALL_PAYPAL_PLANS, paypalPlansFromLs);
    }
    try {
      const resProducts = await httpService.http_get_for_store(
        getPaypalServiceUrl(PayPalConst.api.allPaypalPlans),
      );
      logResData(resProducts, SUBSCRIPTION_ACTIONS_ENUM.GET_ALL_PAYPAL_PLANS);
      const products = getPayPalProductsForState(resProducts);
      if (products && products.length && products.length === 2) {
        localStorage.setItem(AppConst.localstorageConf.paypalPlans, JSON.stringify(products));
        return commit(SUBSCRIPTION_MUTATIONS_ENUM.SET_ALL_PAYPAL_PLANS, products);
      }
      /**
       * If localstorage was not available and allPaypalPlans is still not set
       * then we can use data from server to atleast get 1 product if available
       */
      if (!state.allPaypalPlans && products && products.length) {
        return commit(SUBSCRIPTION_MUTATIONS_ENUM.SET_ALL_PAYPAL_PLANS, products);
      }
    } catch (ex) {
      console.log('Error fetching GET_ALL_PAYPAL_PLANS: ', ex);
    }
  },
  async [SUBSCRIPTION_ACTIONS_ENUM.GET_USER_PAYPAL_SUBSCRIPTION](
    { commit, state },
    { subscriptionId, isForT4 },
  ) {
    let paypalSubscription = null;
    try {
      paypalSubscription = await httpService.http_get_for_store(
        getPaypalServiceUrl(
          PayPalConst.api.subscriptionDetail.replace('{SUBSCRIPTION_ID}', subscriptionId),
          isForT4,
        ),
      );
      logResData(paypalSubscription, SUBSCRIPTION_ACTIONS_ENUM.GET_USER_PAYPAL_SUBSCRIPTION);
      if (paypalSubscription && state.allPaypalPlans) {
        paypalSubscription.planDetails = state.allPaypalPlans.find(
          (plan) => plan.productId === paypalSubscription.plan_id,
        );
      }
      commit(SUBSCRIPTION_MUTATIONS_ENUM.SET_USER_PAYPAL_SUBSCRIPTION, paypalSubscription);
    } catch (ex) {
      console.log(ex);
      paypalSubscription = null;
    }
  },
  async [SUBSCRIPTION_ACTIONS_ENUM.CANCEL_USER_PAYPAL_SUBSCRIPTION]({ dispatch, state }) {
    const loading = await loadingController.create({
      message: AppConst.processingText,
      duration: 10000,
    });

    await loading.present();
    const subscriptionId = state.paypalSubscription.id;
    const cancelPaypalSubscription = await httpService.http_get_for_store(
      getPaypalServiceUrl(
        PayPalConst.api.cancelSubscription.replace('{SUBSCRIPTION_ID}', subscriptionId),
      ),
    );
    if (cancelPaypalSubscription.status) {
      dispatch(SUBSCRIPTION_ACTIONS_ENUM.GET_USER_PAYPAL_SUBSCRIPTION, { subscriptionId });
      toastController.create(PayPalConst.toasts.subscribed.cancelled).then((toast) => {
        toast.present();
      });
    }
    loading.dismiss();
  },
  [SUBSCRIPTION_ACTIONS_ENUM.SET_IF_IAP_SUBSCRIPTION_IS_ACTIVE]({ commit }, payload) {
    return commit(SUBSCRIPTION_MUTATIONS_ENUM.SET_IF_IAP_SUBSCRIPTION_IS_ACTIVE, payload);
  },
  [SUBSCRIPTION_ACTIONS_ENUM.CLEAR_SUBSCRIPTION_ON_LOGOUT]({ commit }) {
    return commit(SUBSCRIPTION_MUTATIONS_ENUM.CLEAR_SUBSCRIPTION_ON_LOGOUT);
  },
  [SUBSCRIPTION_ACTIONS_ENUM.SET_ALL_IAP_PRODUCTS]({ commit }, payload) {
    return commit(SUBSCRIPTION_MUTATIONS_ENUM.SET_ALL_IAP_PRODUCTS, payload);
  },
};
