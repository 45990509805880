<!--
All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->

<template>
  <IonPage>
    <SentinelElement />
    <ion-header>
      <ion-toolbar color="dark">
        <ion-buttons slot="start">
          <menu-button />
          <ion-button
            id="btn-fav"
            fill="solid"
            color="secondary"
            @click="markItemAsFav(settings)"
          >
            <ion-icon
              v-show="isMarkedFavorite"
              class="markedfavorite"
              :icon="star"
            />
            <ion-icon
              v-show="!isMarkedFavorite"
              :icon="star"
            />
          </ion-button>
        </ion-buttons>
        <ion-title id="viewtitle">
          <ion-button
            id="btn-meteo-selector"
            class="custom-button"
            color="secondary"
            @click="openAirportSelectModal"
          >
            {{ settings.start }} {{ productDisplay }}
            <ion-icon
              slot="end"
              mode="ios"
              :icon="chevronDownOutline"
            />
          </ion-button>
        </ion-title>
        <ion-buttons slot="end">
          <ion-button
            v-show="settings.actionKey === 'meteogram'"
            fill="solid"
            color="secondary"
            @click="openQuickChangesPopover"
          >
            <ion-icon :icon="logoBuffer" />
          </ion-button>
          <ion-button
            v-if="showTourOption"
            fill="solid"
            color="secondary"
            class="app-tour-button"
            @click="showtour()"
          >
            <ion-icon :icon="helpCircle" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content
      v-if="isActiveRoute"
      force-overscroll="false"
      class="hasfooter"
    >
      <AppLoader />
      <div
        v-show="settings.actionKey === 'meteogram'"
        class=""
      >
        <meteogram-chart
          ref="chartApi"
          :settings="settings"
        />
      </div>
      <!-- <div
        v-if="!airportTabContent"
        class="tab-data-loading"
      >
        <ion-spinner name="crescent" />
      </div> -->
      <SkewTab
        v-if="settings.actionKey === 'skew-t'"
        :settings="settings"
      />
      <RadarTab
        v-else-if="settings.actionKey === 'radar'"
        :settings="settings"
      />
      <TafsTab
        v-else-if="settings.actionKey === 'tafs'"
        :data="airportTabContent"
      />
      <FcstTab
        v-else-if="settings.actionKey === 'fcstdiscussion'"
        :data="airportTabContent"
      />
      <MetarTab
        v-else-if="settings.actionKey === 'metars'"
        :data="airportTabContent"
        :settings="settings"
      />
      <div
        v-else-if="
          settings.actionKey !== 'meteogram' &&
            settings.actionKey !== 'metars' &&
            settings.actionKey !== 'radar' &&
            settings.actionKey !== 'tafs' &&
            settings.actionKey !== 'skew-t' &&
            settings.actionKey !== 'fcstdiscussion'
        "
        class="airport-tab"
        v-html="airportTabContent"
      />
    </ion-content>
    <ion-footer v-show="!hideBottomAxis">
      <ion-toolbar color="dark">
        <UTCClock />
        <layer-update-time-trigger-button
          :layer-for="getLayerUpdateTimeFor"
        />
      </ion-toolbar>
    </ion-footer>
    <v-tour
      v-if="isActiveRoute"
      name="viewTour"
      :steps="toursteps"
      :callbacks="tourCallbacks"
    />
  </IonPage>
</template>
<script>
import {
  IonContent,
  IonHeader,
  IonFooter,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonSpinner,
  modalController,
} from '@ionic/vue';
import {
  star,
  logoBuffer,
  helpCircle,
  chevronDownOutline,
  chevronUpOutline,
  arrowForward,
  arrowBack,
} from 'ionicons/icons';
import _forEach from 'lodash/forEach';
import _debounce from 'lodash/debounce';
import DefConst from '../../constants/def.constant';
import ChartConst from '../../constants/chart.constant';
import AppConst from '../../constants/app.constant';
import EventConst from '../../constants/event.constant';
import utilityService from '../../services/utility.service';
import dashboardDataSource from './Airport.data';
import ConfigApplicationSettings from '../../config/application.config';
import searchHistoryService from '../../services/searchHistory.service';
import ConfigDeviceSettings from '../../config/device.config';
import MeteogramChart from '../../components/Meteogram/Meteogram.vue';
import MetarTab from '../../components/AirportTabs/Metar.vue';
import RadarTab from '../../components/AirportTabs/Radar.vue';
import TafsTab from '../../components/AirportTabs/Tafs.vue';
import FcstTab from '../../components/AirportTabs/Fcst.vue';
import SkewTab from '../../components/AirportTabs/SkewT.vue';
import AirportForm from '../../components/AirportForm.vue';
import UTCClock from '../../components/UTCClock.vue';
import Emitter from '../../bus';
import d3 from '../../globalexports/d3.export';
import FavoritesService from '../../components/Favorites/Favorites.service';
import AirportService from './Airport.service';
import reportService from '../../services/report.service';
import { getSettingFromLocalstorage } from '../../services/localstorage/getSettingFromLocalstorage';
import routeControllerService from '../../services/routeController.service';
import MeteogramChartService from '../../components/Meteogram/service/meteogramChart.service';
import authGuardService from '../../services/authGuard.service';
import { showAeroplane, hideAeroplane } from '../../services/aeroplane.service';
import { routerPushInterface } from '../../services/router.interface';
import AirportSelector from '../../components/AirportSelector.vue';
import LayerUpdateTimeTriggerButton from '../../components/layerUpdateTime/layerUpdateTimeTriggerButton.vue';
import { ViewKeysMap } from '../../constants/layerUpdateTime.constant';
import * as AltitudeOptionsConst from '../../constants/altitude.constant';

export default {
  name: 'Airport',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonSpinner,
    IonFooter,
    IonButton,
    IonIcon,
    UTCClock,
    MetarTab,
    RadarTab,
    FcstTab,
    TafsTab,
    SkewTab,
    MeteogramChart,
    LayerUpdateTimeTriggerButton,
  },
  data() {
    return {
      star,
      logoBuffer,
      helpCircle,
      chevronDownOutline,
      chevronUpOutline,
      arrowForward,
      arrowBack,
      settings: {},
      aloftData: null,
      meteogramDrawn: false,
      sourceData: '',
      actionName: '',
      tabsListing: [],
      airportTabContent: null,
      lockModal: false,
      altitudeOptions: [],
      meteogramData: {},
      meteogramFullData: null,
      isMarkedFavorite: false,
      toursteps: [],
      hideBottomAxis: utilityService.hideBottomAxis(),
    };
  },
  computed: {
    productDisplay() {
      const vm = this;
      if (vm.settings.actionKey === 'meteogram' && vm.settings.product) {
        return ChartConst.products[vm.settings.product.toLowerCase()]
          .display_name;
      }
      return '';
    },
    showTourOption() {
      return this.settings.actionKey === 'meteogram';
    },
    isActiveRoute() {
      return this.$router.currentRoute.value.name === 'app.dashboard';
    },
    getLayerUpdateTimeFor() {
      const actionKeyMap = {
        meteogram: ViewKeysMap.METEOGRAM,
        tafs: ViewKeysMap.TAFS,
        fcstdiscussion: ViewKeysMap.FCSTDISCUSSION,
        metars: ViewKeysMap.METARS,
      };
      return actionKeyMap[this.settings.actionKey];
    },
  },
  methods: {
    refreshView() {
      this.hideBottomAxis = utilityService.hideBottomAxis();
      this.setTourSteps();
    },
    handleResizeEvent: _debounce(function () {
      this.refreshView();
    }, ChartConst.resizeDebounce),
    ionViewDidEnter() {
      const vm = this;
      vm.initializeView();
      Emitter.on(
        EventConst.refreshViewRootscope + AppConst.stateMap.dashboard.key,
        vm.initializeView,
      );
      reportService.bindReportMethod(AppConst.dataMode.meteogram);
      utilityService.changeTourElementPosition();
      Emitter.on(
        EventConst.favorite.listRefreshed,
        vm.isMarkedFavoriteCheckForStar,
      );
      vm.setTourSteps();
      vm.$nextTick(() => {
        window.addEventListener('resize', this.handleResizeEvent);
      });
    },
    ionViewWillLeave() {
      const vm = this;
      utilityService.stopTour();
      Emitter.off(
        EventConst.refreshViewRootscope + AppConst.stateMap.dashboard.key,
      );
      Emitter.off(
        EventConst.favorite.listRefreshed,
        vm.isMarkedFavoriteCheckForStar,
      );
      reportService.unbindReportMethod();
      window.removeEventListener('resize', this.handleResizeEvent);
    },
    syncRouteData() {
      const vm = this;
      if (vm.settings) {
        const queryParam = {
          start: vm.settings.start ? vm.settings.start.toUpperCase() : '',
          product: vm.settings.product,
          show12HourView: vm.settings.show12HourView,
          actionKey: vm.settings.actionKey,
        };
        routerPushInterface({
          query: queryParam,
        });
        utilityService.setSettingInLocalstorage(
          vm.settings,
          AppConst.localstorageConf.meteogramSettingKey,
        );
        if (vm.day7SyncWithAirport()) {
          dashboardDataSource.sharedStartAirport = vm.settings.start;
        }
      }
    },
    day7SyncWithAirport() {
      return ConfigApplicationSettings.settings.synch7DayAirport;
    },
    getActionByKey(action) {
      const vm = this;
      if (
        vm.tabsListing
        && vm.tabsListing.length
        && vm.tabsListing[
          AirportService.getParamIndexForAction(action, vm.tabsListing, true)
        ]
      ) {
        vm.actionName = vm.tabsListing[
          AirportService.getParamIndexForAction(action, vm.tabsListing, true)
        ].text;
      }
    },
    openQuickChangesPopover(event) {
      const vm = this;
      routeControllerService.openQuickChangesPopover(vm, event);
    },
    loadDashboardSetting() {
      const vm = this;
      if (ConfigApplicationSettings.settings) {
        const myPreviousSettings = getSettingFromLocalstorage(
          AppConst.localstorageConf.meteogramSettingKey,
        );
        vm.settings = {
          start:
            dashboardDataSource.sharedStartAirport
            || this.$route.query.start
            || (myPreviousSettings
              ? myPreviousSettings.start
              : ChartConst.defaultStart), // Supported
          maximumAltitudeAllowed: myPreviousSettings
            ? myPreviousSettings.maximumAltitudeAllowed === undefined
              ? AltitudeOptionsConst.MSL12K.feet
              : myPreviousSettings.maximumAltitudeAllowed
            : AltitudeOptionsConst.MSL12K.feet,
          ruleset: ChartConst.defaultRuleset, // Supported
          product:
            this.$route.query.product
            || (myPreviousSettings && myPreviousSettings.product
              ? myPreviousSettings.product
              : ChartConst.defaultProduct),
          show12HourView:
            this.$route.query.show12HourView
            || (myPreviousSettings && myPreviousSettings.show12HourView
              ? myPreviousSettings.show12HourView
              : ChartConst.defaultShow12HourView),
          actionKey:
            this.$route.query.actionKey
            || (myPreviousSettings && myPreviousSettings.actionKey
              ? myPreviousSettings.actionKey
              : ChartConst.defaultAction),
          dataMode: AppConst.dataMode.meteogram,
          timeZone: ConfigApplicationSettings.settings.timeZone,
          useLogarithmicScaleForCeiling:
            ConfigDeviceSettings.settings.useLogarithmicScaleForCeiling,
          temperatureUnit: ConfigApplicationSettings.settings.temperatureUnit,
          enableCloudBelowCig:
            ConfigDeviceSettings.settings.enableCloudBelowCig,
          testmode: ConfigDeviceSettings.settings.enableTestMode,
        };
        this.altitudeOptions = DefConst.altitudeOptions;
      }
    },
    loadViewVairables() {
      const vm = this;
      const queryParam = {
        start: vm.$route.query.start,
        product: vm.$route.query.product,
        show12HourView: vm.$route.query.show12HourView,
        actionKey: vm.$route.query.actionKey,
      };
      if (vm.$route.query.start) {
        vm.settings.start = vm.$route.query.start;
      } else if (!vm.$route.query.start) {
        queryParam.start = vm.settings.start;
      }
      if (vm.$route.query.product) {
        vm.settings.product = vm.$route.query.product;
      } else if (!vm.$route.query.product) {
        queryParam.product = vm.settings.product;
      }
      if (vm.$route.query.show12HourView) {
        vm.settings.show12HourView = vm.$route.query.show12HourView;
      } else if (!vm.$route.query.show12HourView) {
        queryParam.show12HourView = vm.settings.show12HourView;
      }
      if (vm.$route.query.actionKey) {
        vm.settings.actionKey = vm.$route.query.actionKey;
      } else if (!vm.$route.query.actionKey) {
        queryParam.actionKey = vm.settings.actionKey;
      }
      if (!authGuardService.checkIsAirportTabAllowed(vm.settings.actionKey)) {
        // if coming from fav or bookmark and user is not premium then dont allow
        vm.settings.actionKey = 'meteogram';
        queryParam.actionKey = vm.settings.actionKey;
      }

      routerPushInterface({
        query: queryParam,
      });
    },
    isActionKeyAvailable() {
      const vm = this;
      let tabAvailable = false;
      _forEach(vm.tabsListing, (value) => {
        if (value.key === vm.settings.actionKey) {
          tabAvailable = true;
        }
      });
      if (tabAvailable === false) {
        vm.settings.actionKey = ChartConst.defaultAction;
        vm.syncRouteData();
      }
    },
    loadMeteogramView(noDataForAloftAvailable) {
      const vm = this;
      if (vm.meteogramFullData) {
        switch (vm.settings.product) {
          case ChartConst.products.lamp.name:
            vm.sourceData = vm.meteogramFullData.lamp;
            break;
          case ChartConst.products.mav.name:
            vm.sourceData = vm.meteogramFullData.mav;
            break;
          case ChartConst.products.twelvehours.name:
            vm.sourceData = vm.meteogramFullData.twelvehours;
            break;
          default:
        }
        vm.settings.airportDetails = utilityService.getMatchedAirportObject(
          vm.settings.start,
        );
        vm.meteogramDrawn = true;
        if (vm.$refs.chartApi) {
          // if child component is inititalized
          vm.$refs.chartApi.refresh(
            vm.sourceData,
            vm.meteogramFullData,
            vm.aloftData,
            noDataForAloftAvailable,
          );
        }
      }
    },
    initAloft(data) {
      const vm = this;
      AirportService.getMeteogramAloftData(
        data.lamp.lat,
        data.lamp.lng,
        data.lamp.icao_id,
        vm.settings,
      ).then((aloftData) => {
        if (
          vm.settings
          && vm.settings.airportDetails
          && vm.settings.airportDetails.el > vm.settings.maximumAltitudeAllowed
        ) {
          // if elevation is maximumAltitudeAllowed
          aloftData = null;
        }
        vm.aloftData = aloftData;
        vm.loadMeteogramView(!!(aloftData === null));
      });
    },
    initMeteogramDraw() {
      const vm = this;
      if (!vm.meteogramFullData) {
        return;
      }
      if (vm.meteogramFullData.noDataAvailable) {
        // If there is no data then show message that no data is available
        MeteogramChartService.showNoDataStrip();
      } else {
        vm.initAloft(vm.meteogramFullData);
        searchHistoryService.loadAirportHistorySearch();
      }
    },
    markItemAsFav(item) {
      FavoritesService.markItemAsFav(item);
    },
    init() {
      const vm = this;
      showAeroplane();
      utilityService.clearAuditFactory();
      const requestSettings = utilityService.clone(vm.settings);
      vm.aloftData = null;
      vm.meteogramDrawn = false;
      vm.isMarkedFavorite = FavoritesService.isMarkedFavorite(vm.settings);
      AirportService.getMeteogramData(requestSettings).then((data) => {
        vm.meteogramFullData = data;
        if (data) {
          if (data.airportTabs) {
            vm.tabsListing = data.airportTabs;
            vm.getActionByKey(vm.settings.actionKey);
            vm.getAirportTabData(true);
          } else {
            hideAeroplane();
          }
        } else {
          hideAeroplane();
        }
      });
    },
    cleanTab() {
      setTimeout(() => {
        // Remove broken links
        d3.select('#contact').remove();
        d3.select('#disclaimer').remove();
        d3.select('#policy').remove();
        d3.selectAll('.airport-tab style').remove();
        d3.selectAll('.airport-tab pre').style('position', 'absolute');
        setTimeout(() => {
          d3.selectAll('.airport-tab pre').style('position', 'inherit');
        }, 1000);
        d3.select('.airport-tab pre').on('touchmove', (d3Event) => {
          if (d3Event) {
            d3Event.stopPropagation();
          } else if (window.event) {
            window.event.stopPropagation();
          }
        });
      });
    },
    isTabSelectedMeteogram() {
      return this.settings && this.settings.actionKey === 'meteogram';
    },
    getAirportTabData() {
      const vm = this;
      vm.isActionKeyAvailable();
      if (vm.isTabSelectedMeteogram()) {
        //vm.airportTabContent = 'metegoramdata';
        if (vm.meteogramDrawn === false) {
          vm.initMeteogramDraw();
        }
      } else if (vm.settings && vm.settings.start) {
        hideAeroplane();
        AirportService.getAirportTabData(
          vm.settings.start,
          vm.settings.actionKey,
          ConfigApplicationSettings.showMobileView,
          vm.tabsListing,
          vm.settings.airportDetails,
        ).then((responseData) => {
          vm.airportTabContent = responseData.actionData;
          vm.cleanTab();
        });
      } else {
        hideAeroplane();
      }
    },
    openAirportSelectModal() {
      const vm = this;
      if (vm.lockModal) {
        return;
      }
      vm.lockModal = true;
      let component = AirportSelector;
      let componentProps = { selectorInput: vm.settings.start };
      let setSettings = (formData) => {
        vm.settings.start = formData.icao_id.toUpperCase();
      };
      if (vm.settings.actionKey === 'meteogram') {
        component = AirportForm;
        componentProps = {
          settings: vm.settings,
          title: 'Meteogram',
        };
        setSettings = (formData) => {
          vm.settings.start = formData.start.toUpperCase();
          vm.settings.product = formData.product;
          vm.settings.maximumAltitudeAllowed = formData.maximumAltitudeAllowed;
        };
      }
      modalController
        .create({
          component,
          backdropDismiss: false,
          componentProps,
        })
        .then((modalElement) => {
          modalElement.present();
          modalElement.onDidDismiss().then((res) => {
            vm.lockModal = false;
            const formData = res.data;
            if (formData) {
              setSettings(formData);
              vm.airportTabContent = null;
              vm.meteogramFullData = null;
              vm.meteogramDrawn = false;
              MeteogramChartService.cleanMeteogramChart();
              vm.init();
              vm.syncRouteData();
            }
          });
        });
    },
    initializeView() {
      this.loadDashboardSetting();
      this.loadViewVairables();
      this.init();
      this.syncRouteData();
    },
    showtour() {
      this.$tours.viewTour.start();
    },
    isMarkedFavoriteCheckForStar() {
      FavoritesService.isMarkedFavoriteCheckForStar(this, this.settings);
    },
    setTourSteps() {
      utilityService.setTourSteps(this, 'app.dashboard');
    },
  },
};
</script>
<style scoped>
.airport-tab {
  width: 100%;
  height: 100%;
  text-align: left;
}
html.ios .airport-tab {
  overflow-x: scroll;
}
</style>
