import _forEach from 'lodash/forEach';
import Sentry from './sentry.import';
import { setUserContext } from './setUserContext';

const isError = (e) => e
  && e.stack && e.message
  && typeof e.stack === 'string' && typeof e
  // In some cases an Event is thrown as an error which causes Sentry
  // to throw another error called "Non-Error exception" which is a false positive
  // This will help wrap the Event into a Error object.
  // On such case is in some versions of Safari on iOS, Request Time out
  // will cause an Event instead of Error
  // https://github.com/getsentry/sentry-javascript/issues/2546#issuecomment-703331367
  .message === 'string' && !(e instanceof Event);

const appendTags = (extraTag, scope) => {
  let defaultTags = {};
  if (extraTag) {
    defaultTags = extraTag;
  }
  const allTags = Object.keys(defaultTags);
  if (allTags) {
    _forEach(allTags, (tagItem) => {
      scope.setTag(tagItem, defaultTags[tagItem]);
    });
  }
};
const reportToSentry = (errorData, extraData, extraTag) => {
  try {
    let errorMessage;
    let tempError;
    /// Setting user context Start
    setUserContext();
    /// Setting user context End
    if (typeof errorData.exception !== 'string') {
      errorMessage = errorData.message || (errorData.exception
        ? errorData.exception.message
        : '');
    } else {
      errorMessage = errorData.exception;
    }
    if (!errorMessage) {
      errorMessage = JSON.stringify(errorData);
    }
    Sentry.withScope((scope) => {
      /// Tags Start
      appendTags(extraTag, scope);
      /// Tags END
      Object.keys(extraData).forEach((key) => {
        scope.setExtra(key, extraData[key]);
      });
      if (isError(errorData)) {
        Sentry.captureException(errorData);
      } else if (errorMessage) {
        Sentry.captureMessage(errorMessage, {
          extra: errorData,
        });
      }
    });
  } catch (ee) {
    console.log(ee);
  }
};

export default reportToSentry;
