import AppConst from '../../../../constants/app.constant';

const getPayPalPlansFromLs = () => {
  let paypalPlansFromLs = null;
  try {
    const dataPaypalPlansFromLs = JSON.parse(localStorage.getItem(AppConst.localstorageConf.paypalPlans));
    if (dataPaypalPlansFromLs) {
      paypalPlansFromLs = dataPaypalPlansFromLs;
    }
    return paypalPlansFromLs;
  } catch (ex) {
    console.log('Error PaypalPlansFromLs: ', paypalPlansFromLs);
    return paypalPlansFromLs;
  }
};

export { getPayPalPlansFromLs };
