<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
  -->

<template>
  <ion-page>
    <SentinelElement />
    <ion-header>
      <ion-toolbar color="dark">
        <ion-buttons slot="start">
          <menu-button />
        </ion-buttons>
        <ion-title>Developer</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="hasfooter">
      <ion-list>
        <ion-item>
          <ion-label position="stacked">
            Legacy API URL
          </ion-label>
          <ion-input
            v-model.trim="serviceHost"
            placeholder="Enter Service Host"
          />
          <ion-input
            :value="derivedServiceHostPreference"
            readonly="true"
          />
          <ion-buttons slot="end">
            <ion-button
              slot="end"
              fill="solid"
              color="primary"
              @click="setDeveloperConfigPreference('serviceHost')"
            >
              <ion-icon slot="icon-only" :icon="save" />
            </ion-button>
            <ion-button
              slot="end"
              fill="solid"
              color="primary"
              @click="copySettingValue('serviceHost')"
            >
              <ion-icon slot="icon-only" :icon="copy" />
            </ion-button>
            <ion-button
              slot="end"
              fill="solid"
              color="danger"
              @click="clearDeveloperConfigPreference('serviceHost')"
            >
              <ion-icon slot="icon-only" :icon="trash" />
            </ion-button>
          </ion-buttons>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">
            Supa API URL
          </ion-label>
          <ion-input
            v-model.trim="supaApiUrl"
            placeholder="Enter Supabase Api Url"
          />
          <ion-input
            :value="derivedSupaApiUrl"
            readonly="true"
          />
          <ion-buttons slot="end">
            <ion-button
              slot="end"
              fill="solid"
              color="primary"
              @click="setDeveloperConfigPreference('supaApiUrl')"
            >
              <ion-icon slot="icon-only" :icon="save" />
            </ion-button>
            <ion-button
              slot="end"
              fill="solid"
              color="primary"
              @click="copySettingValue('supaApiUrl')"
            >
              <ion-icon slot="icon-only" :icon="copy" />
            </ion-button>
            <ion-button
              slot="end"
              fill="solid"
              color="danger"
              @click="clearDeveloperConfigPreference('supaApiUrl')"
            >
              <ion-icon slot="icon-only" :icon="trash" />
            </ion-button>
          </ion-buttons>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">
            Supa Public Key
          </ion-label>
          <ion-input
            v-model.trim="supaApiKey"
            placeholder="Enter Supabase Api Key"
          />
          <ion-input
            :value="derivedSupaApiKey"
            readonly="true"
          />
          <ion-buttons slot="end">
            <ion-button
              slot="end"
              fill="solid"
              color="primary"
              @click="setDeveloperConfigPreference('supaApiKey')"
            >
              <ion-icon slot="icon-only" :icon="save" />
            </ion-button>
            <ion-button
              slot="end"
              fill="solid"
              color="primary"
              @click="copySettingValue('supaApiKey')"
            >
              <ion-icon slot="icon-only" :icon="copy" />
            </ion-button>
            <ion-button
              slot="end"
              fill="solid"
              color="danger"
              @click="clearDeveloperConfigPreference('supaApiKey')"
            >
              <ion-icon slot="icon-only" :icon="trash" />
            </ion-button>
          </ion-buttons>
        </ion-item>
        <ion-item>
          <ion-label>LocalStorage: {{ totalLsSize }}</ion-label>
          <ion-buttons slot="end">
            <ion-button
              slot="end"
              fill="solid"
              color="primary"
              @click="showLsDetails()"
            >
              <ion-icon slot="icon-only" :icon="informationCircle" />
            </ion-button>
            <ion-button
              slot="end"
              fill="solid"
              color="danger"
              @click="clearLs()"
            >
              <ion-icon slot="icon-only" :icon="trash" />
            </ion-button>
          </ion-buttons>
        </ion-item>
        <ion-item>
          <ion-label>Environment</ion-label>
          <ion-badge slot="end">
            {{ environment }}
          </ion-badge>
        </ion-item>
        <div
          v-for="(control, $index) in enabledDevelopersControl"
          :key="$index"
        >
          <ion-item v-if="control.type == 'toggle'">
            <ion-label>{{ control.display }}</ion-label>
            <ion-toggle
              v-model="settings[control.key]"
              @ionChange="savePreferences()"
            />
          </ion-item>
          <ion-item v-else-if="control.type == 'number'">
            <ion-label position="stacked">
              {{ control.display }}
            </ion-label>
            <ion-input
              v-model.number="settings[control.key]"
              type="number"
              :placeholder="control.placeholder"
              @ionBlur="savePreferences()"
            />
          </ion-item>
          <ion-item v-else-if="control.type == 'text'">
            <ion-label position="stacked">
              {{ control.display }}
            </ion-label>
            <ion-input
              v-model.trim="settings[control.key]"
              type="text"
              :placeholder="control.placeholder"
              @ionBlur="savePreferences()"
            />
          </ion-item>
        </div>
      </ion-list>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button fill="solid" color="secondary" @click="showErrorTrigger">
            <ion-icon :icon="bug" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
import {
  IonContent,
  IonHeader,
  IonFooter,
  IonList,
  IonItem,
  IonInput,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonLabel,
  IonBadge,
  IonToggle,
  alertController,
  actionSheetController,
} from '@ionic/vue';
import {
  informationCircle, trash, bug, save, copy,
} from 'ionicons/icons';
import ConfigDeviceSetting from '../config/device.config';
import AppConst from '../constants/app.constant';
import DeveloperConst from '../constants/developer.constant';
import rulesetService from '../services/ruleset.service';
import capacitorService from '../services/capacitor.service';
import utilityService from '../services/utility.service';
import EventConst from '../constants/event.constant';
import Emitter from '../bus';
// eslint-disable-next-line import/no-cycle
import reportError from '../services/error-handler/reportError';
// eslint-disable-next-line import/no-cycle
import httpService from '../services/http.service';
import { saveToLocalStorage } from '../services/localstorage/saveToLocalStorage';
import stormTrackDataService from './Mapview/stormTracks/stormTrackDataService';
import { getServiceHostPreference } from '../services/utils/getServiceHostPreference';
import { getKeyValueFromLocalStorage } from '../services/localstorage/getKeyValueFromLocalStorage';
import { getDerivedSupabaseConfig } from '../supabaseClient';

export default {
  name: 'Developer',
  components: {
    IonFooter,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonToggle,
    IonButtons,
    IonIcon,
    IonLabel,
    IonList,
    IonItem,
    IonBadge,
    IonInput,
  },
  data() {
    return {
      informationCircle,
      trash,
      bug,
      save,
      copy,
      settings: {},
      supaApiUrl: this.getDeveloperConfigPreference('supaApiUrl'),
      supaApiKey: this.getDeveloperConfigPreference('supaApiKey'),
      serviceHost: this.getDeveloperConfigPreference('serviceHost'),
      showAdvanced: false,
      rulesetToUse: '',
      environment: '',
      totalLsSize: 0,
      developerConst: DeveloperConst,
      showingAlert: false,
    };
  },
  computed: {
    enabledDevelopersControl() {
      return this.developerConst.controls.filter((item) => item.enabled);
    },
    derivedServiceHostPreference() {
      return `Derived:${getServiceHostPreference(true)}`;
    },
    derivedSupaApiUrl() {
      return `Derived:${getDerivedSupabaseConfig(true).endpoint}`;
    },
    derivedSupaApiKey() {
      return `Derived:${getDerivedSupabaseConfig(true).key}`;
    },
  },
  methods: {
    ionViewDidEnter() {
      this.getLSDetails();
      this.environment = utilityService.getEnvString();
      this.settings = { ...ConfigDeviceSetting.settings };
      this.rulesetToUse = 'i';
    },
    ionViewWillLeave() {
      // Before destory i.e. changing view save settings to Localstorage
      DeveloperConst.controls.forEach((item) => {
        if (item.type === 'number') {
          const numberValue = parseInt(
            ConfigDeviceSetting.settings[item.key],
            10,
          );
          ConfigDeviceSetting.settings[item.key] = numberValue;
        }
      });
    },
    savePreferences() {
      ConfigDeviceSetting.settings = { ...this.settings };
      ConfigDeviceSetting.setDeviceSpecificSetting();
      rulesetService.loadRulesetConfiguration();
      Emitter.emit(EventConst.favorite.listRefreshed);
      stormTrackDataService.resetStormTrackService();
    },
    async copySettingValue(key) {
      if (key === 'serviceHost') {
        await navigator.clipboard.writeText(getServiceHostPreference(true));
      } else if (key === 'supaApiUrl') {
        await navigator.clipboard.writeText(getDerivedSupabaseConfig().endpoint);
      } else if (key === 'supaApiKey') {
        await navigator.clipboard.writeText(getDerivedSupabaseConfig().key);
      }
    },
    getDeveloperConfigPreference(key) {
      return getKeyValueFromLocalStorage(key) || '';
    },
    setDeveloperConfigPreference(key) {
      saveToLocalStorage(
        AppConst.localstorageConf.prefix + AppConst.localstorageConf[key],
        this[key],
      );
    },
    clearDeveloperConfigPreference(key) {
      this[key] = '';
      localStorage.removeItem(AppConst.localstorageConf.prefix + AppConst.localstorageConf[key]);
    },
    getLSDetails() {
      let lsTotal = 0;
      let xLen;
      const localStorageKeys = Object.keys(window.localStorage);
      let xSize;
      this.lsRecords = [];
      localStorageKeys.forEach((item) => {
        xLen = (window.localStorage[item].length + item.length) * 2;
        lsTotal += xLen;
        xSize = xLen / 1024;
        this.lsRecords.push(`${item.substr(0, 50)} = ${xSize.toFixed(2)} KB`);
      });
      this.totalLsSize = lsTotal / 1024;
      this.totalLsSize = `${this.totalLsSize.toFixed(2)} KB`;
      this.lsRecords.push(`Total: ${this.totalLsSize}`);
    },
    showLsDetails() {
      this.getLSDetails();
      // eslint-disable-next-line no-alert
      window.alert(this.lsRecords.join('\n'));
    },
    confirmLsCleaning() {
      if (!this.showingAlert) {
        alertController
          .create({
            header: 'Confirm!',
            message: AppConst.messageToClearLS,
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler() {
                  this.showingAlert = false;
                },
              },
              {
                text: 'Yes',
                handler() {
                  capacitorService.showSplashScreen();
                  window.localStorage.clear();
                  window.location.reload();
                },
              },
            ],
          })
          .then((alert) => {
            alert.present();
            this.showingAlert = true;
          });
      }
    },
    clearLs() {
      this.confirmLsCleaning();
    },
    forceError(jsError) {
      if (jsError) {
        reportError({
          message: `Forcing error fooboo tester: ${new Date()}`,
          code: 'Forcing error fooboo tester.',
          emailTried: 'Forcing error fooboo. tester',
          name: 'Forcing error fooboo tester.',
        });
        console.log('Error sent');
      } else {
        // Bad request here
        httpService.http_get(AppConst.weatherApi);
      }
    },
    showErrorTrigger() {
      const vm = this;
      actionSheetController
        .create({
          buttons: [
            {
              text: 'JavaScript',
              handler() {
                vm.forceError(true);
              },
            },
            {
              text: 'HTTP',
              handler() {
                vm.forceError(false);
              },
            },
            {
              text: 'Cancel',
              role: 'cancel',
            },
          ],
        })
        .then((actionSheet) => {
          actionSheet.present();
        });
    },
  },
};
</script>
<style scoped>
.segments-developer {
  justify-content: left;
  margin-left: 10px;
}
</style>
